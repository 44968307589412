import { useEffect, useState } from 'react';

export default function ReportsPage() {
  const [reportUrl, setReportUrl] = useState('');

  useEffect(() => {
    const getReportUrl = async () => {
      const res = await fetch('https://btp-dev.greenm.io/api/dashboard');
      const url = await res.text();

      setReportUrl(url);
    };

    getReportUrl();
  }, []);

  return (
    <>
      <div className="layout-wrap">Reports Page</div>
      {reportUrl && (
        <iframe
          className="reports-dashboard"
          title=" Test Dashboard"
          width="960"
          height="720"
          src={reportUrl}
        ></iframe>
      )}
    </>
  );
}
