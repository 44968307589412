import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'services/aws';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode> disabled due to double rendering in React 18
  // https://reactjs.org/blog/2022/03/29/react-v18.html#new-strict-mode-behaviors

  <App />,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
